@font-face {
  font-family: 'ProximaNova Regular';
  src: url('https://assets.hover.to/fonts/proximanovareg.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham Book';
  src: url('https://assets.hover.to/fonts/gotham-book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham Medium';
  src: url('https://assets.hover.to/fonts/gotham-medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham Light';
  src: url('https://assets.hover.to/fonts/gotham-light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham Bold';
  src: url('https://assets.hover.to/fonts/gotham-bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'HoverDingbats';
  src: url('https://assets.hover.to/fonts/hoverdingbats.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// fixes a scroll-related issue for Ipad view of Estimator
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  body {
    -webkit-overflow-scrolling: touch;
  }
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 100%;
  box-sizing: border-box;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  color: #4d4d4d;
  cursor: auto;
  font-family: 'ProximaNova Regular', 'Helvetica Neue', 'Helvetica', Helvetica,
    Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
}

h1 {
  font-family: 'ProximaNova Light', 'Helvetica Neue', 'Helvetica', Helvetica,
    Arial, sans-serif;
  margin: 0.5rem 0 0;
  font-weight: unset;
}

a {
  text-decoration: none;
}

p {
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.6;
  margin-bottom: 1.25rem;
  text-rendering: optimizeLegibility;
}

/* This removes browser default styling for input[type=number] */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

#chakra-toast-manager-bottom {
  padding: 0 !important;
}
